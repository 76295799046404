import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const projectVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const Project = ({ title }) => {
  const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
     z-30 flex flex-col justify-center items-center text-center p-16 text-white`;
  const projectTitle = title.split(" ").join("-").toLowerCase();

  return (
    <motion.div variants={projectVariant} className="relative">
      <div className={overlayStyles}>
        <p className="text-2xl font-playfair">{title}</p>
      </div>
      <img
        loading="lazy"
        src={`../assets/${projectTitle}.jpg`}
        alt={projectTitle}
      />
    </motion.div>
  );
};

const Projects = () => {
  return (
    <section id="projects" className="pt-32 pb-48">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-red">PRO</span>JECTS
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>
        <p className="mt-10 mb-10">
          These are some of my concept designs that I have worked on.
        </p>
      </motion.div>

      {/* PROJECTS */}
      <div className="flex justify-center">
        <motion.div
          className="sm:grid sm:grid-cols-3"
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* ROW 1 */}
          <div
            className="flex justify-center text-center items-center p-10 bg-red-800
              max-w-[600px] max-h-[600px] text-2xl font-playfair font-semibold"
          >
            CONCEPT DESIGN
          </div>
          <Project title="Reverse" />
          <Project title="Rolling" />

          {/* ROW 2 */}
          <Project title="Geometry" />
          <Project title="Puzzle" />
          <Project title="Center" />

          {/* ROW 3 */}
          <Project title="Pett" />
          <Project title="Flower Light" />
          <Project title="Steps" />

          {/* ROW 4 */}
          <Project title="Road" />
          <Project title="Part" />
          <Project title="Union" />

          {/* ROW 5 */}
          <Project title="C10" />
          <Project title="Curve" />
          <Project title="Companion" />
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
