import LineGradient from "../components/LineGradient";
import useMediaQuery from "../hooks/useMediaQuery";
import { motion } from "framer-motion";

const MySkills = () => {
  const isAboveLarge = useMediaQuery("(min-width: 1060px)");
  return (
    <section id="about me" className=" pb-32">
      {/* HEADER AND IMAGE SECTION */}
      <div className="md:flex md:justify-between md:gap-16 mt-24">
        <motion.div
          className="md:w-2/3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, x: -50 },
            visible: { opacity: 1, x: 0 },
          }}
        >
          <p className="font-playfair font-semibold text-5xl mb-5">
            ABOUT <span className="text-red-600">ME</span>
          </p>
          <LineGradient width="1/3" />
          <p className="mt-10 mb-7 text-sm text-justify">
            Taking apart, imagining new shapes and reassembling toys in a
            totally different way were part of my childhood. Today, this fun is
            part of my life. I started my studies at El Bosque University in
            Bogotá, Colombia; where I began to understand this passion and
            process of design. I learnt to create things with my own hands and
            it let me to work with different materials during that period. I
            continued my studies at the Universitat of Barcelona as Designer,
            where I got most of my basics, process and knowledge of different
            types of design. After that, keeping my curiosity and my wish to
            work with hands, I learnt and adquire experienced in woodcraft and
            developing furniture by myself. All this process helped me grow as a
            professional but still want to learn more.
          </p>
        </motion.div>

        <div className="mt-16 md:mt-0">
          {isAboveLarge ? (
            <div
              className="relative z-0 ml-20 mr-44 before:absolute before:rounded-3xl before:-top-8 before:-left-10
              before:w-full before:h-full before:border-2 before:border-white before:z-[-1]"
            >
              <img
                alt="skills"
                className="z-10 w-60 mr-20"
                src="assets/skill1.png"
              />
            </div>
          ) : (
            <img alt="skills" className="z-10 w-96" src="assets/skill1.png" />
          )}
        </div>
      </div>

      {/* SKILLS */}
      <div className="md:flex md:justify-between mt-16 gap-32">
        {/* EXPERIENCE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">01</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Observation
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-blue absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            Every object, detail or place can be transformed into something new.
            Visualizing colours, textures and shapes is what helps me to
            generate new ideas.
          </p>
        </motion.div>

        {/* INNOVATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">02</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Inspiration
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-red absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            Experience new places and different environments helps me to get
            inspiration. It can come from different situations. Sometimes comes
            from a normal walk or a daily activity, that's why always keep my
            little sketchbook and pencil with me.
          </p>
        </motion.div>
        {/* IMAGINATIVE */}
        <motion.div
          className="md:w-1/3 mt-10"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
        >
          <div className="relative h-32">
            <div className="z-10">
              <p className="font-playfair font-semibold text-5xl">03</p>
              <p className="font-playfair font-semibold text-3xl mt-3">
                Simplicity
              </p>
            </div>
            <div className="w-1/2 md:w-3/4 h-32 bg-yellow absolute right-0 top-0 z-[-1]" />
          </div>
          <p className="mt-5">
            In my process, I could figure out more about me and my designs. I
            feel that simplicity is more related as part of my life at the
            moment of creating a new piece. I think that this represents more
            about me and what I want to transmit.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default MySkills;
