import LineGradient from "../components/LineGradient";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";

const Contact = () => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  return (
    <section id="contact" className="contact py-48">
      {/* HEADINGS */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: 50 },
          visible: { opacity: 1, x: 0 },
        }}
        className="flex justify-end w-full"
      >
        <div>
          <p className="font-playfair font-semibold text-6xl text-white">
            <span className="text-red-600">LET'S WORK</span> TOGETHER
          </p>
          <div className="flex md:justify-end my-5">
            <LineGradient width="w-1/2" />
          </div>
        </div>
      </motion.div>
      <div className="md:flex md:justify-between gap-16 mt-5">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 },
          }}
          className="basis-1/2 mt-10 md:mt-0"
        >
          <div className="font-playfair flex gap-12 pt-40">
            <div className="text-5xl text-red-600">
              <h4>Find Me:</h4>
            </div>
            <div className="text-3xl underline">
              <ul className="flex flex-col">
                <a
                  className="hover:opacity-50 transition duration-500"
                  href="https://www.instagram.com/jodehdesign/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </a>

                {/* <a
                  className="pt-4 hover:opacity-50 transition duration-500"
                  href="https://wa.me/593990078759"
                  target="_blank"
                  rel="noreferrer"
                >
                  Whatsapp
                </a> */}

                <a
                  className="pt-4 hover:opacity-50 transition duration-500 "
                  href="mailto:josuejp12@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Email
                </a>

                <a
                  className="pt-4 hover:opacity-50 transition duration-500 "
                  href="https://www.linkedin.com/in/josue-perez-9b6445aa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LinkedIn
                </a>

                <a
                  className="pt-4 hover:opacity-50 transition duration-500 "
                  href="https://www.megosu.com/profile/josue-perez-2867ef2b"
                  target="_blank"
                  rel="noreferrer"
                >
                  Megosu
                </a>
              </ul>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;
